import React from "react"
import arrowRight from "@images/common/ic-arrow-right.svg"
import { MAIN_BUTTON } from "@constants"
import Button from "@common/Button/Button"
import { OffersSectionContainer, OfferItem } from "./OffersSection.style"
import {
  ButtonWrapper,
  Description,
  SecondaryTitle,
} from "../../../../styles/CommonStyles"

const OFFER_ITEMS = [
  {
    title: "Product development",
    description: `Whether you're looking to build a quick prototype or a full
    scale product, Impressit team is your go-to technology partner.
    We effectively manage all project stages, such as: planning,
    UX/UI design, development, testing, deployment and maintenance.`,
    href: "/product-development",
    buttonText: "See how it works",
  },
  {
    title: "Dedicated teams",
    description: `For startups and companies with in-house tech leaders, Impressit
    offers a managed team extension service. It's perfect for
    companies who experience fast growth and have experience working
    with remote agile teams.`,
    href: "/dedicated-teams",
    buttonText: "Read about the teams",
  },
]

const OffersSection = ({ configuration = OFFER_ITEMS, hasTitle = false }) => (
  <OffersSectionContainer hasTitle={hasTitle}>
    {configuration.map(({ title, description, href, buttonText }) => (
      <OfferItem key={title}>
        <SecondaryTitle margins="1">{title}</SecondaryTitle>
        <Description margin="2.5">
            {description}
        </Description>
        <ButtonWrapper href={href}>
          <Button
            text={buttonText}
            bannerButtonIconPath={arrowRight}
            type={MAIN_BUTTON}
          />
        </ButtonWrapper>
      </OfferItem>
    ))}
  </OffersSectionContainer>
)

export default OffersSection
